import {
  AutoComplete,
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  Space,
} from 'antd';
import Search from 'antd/lib/input/Search';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiMoreVertical } from 'react-icons/fi';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import UserModal from '../../../../pages/profile/UserModal';
import { getDataApi } from '../../../utility/APIHooks';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '../../../utility/AppContextProvider/LayoutContextProvider';
import { useSidebarContext } from '../../../utility/AppContextProvider/SidebarContextProvider';
import { useAuthUser } from '../../../utility/AuthHooks';
import AppNotifications from '../../AppNotifications';
import AppHorizontalNav from '../components/AppHorizontalNav';
import AppLogo from '../components/AppLogo';
import UserInfo from '../components/UserInfo';
import FilterModal from './FilterModal';
import './index.style.less';

const AppHeader = ({ showDrawer }) => {
  const { Header } = Layout;
  const { sidebarColorSet } = useSidebarContext();
  const location = useLocation();
  const [options, setOptions] = useState([]);
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);
  const [totalUnreadChat, setTotalUnreadChat] = useState(0);

  const { user } = useAuthUser();
  const navigate = useNavigate();

  const onSelect = (v) => {
    setKeyword(v.split('---')[0]);
    setTimeout(() => {
      navigate(`/search?kw=${v}`);
    }, 20);
  };

  const getUnreadChat = async () => {
    const { total } = (await getDataApi('/chats/unread')) || {};
    setTotalUnreadChat(total);
  };

  useEffect(() => {
    if (user) {
      getUnreadChat();
      const interval = setInterval(() => {
        getUnreadChat();
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [user]);

  const handleSearch = (v) => {
    setOptions(
      !v
        ? []
        : [
            {
              value: `${v}---all`,
              label: (
                <div className='search-dropdown'>
                  <span
                    style={{ marginRight: 12 }}
                    className='wc-icon icon-ic_arrow-up-right '></span>
                  <Text className='pre-text'>{`Search "${v}"`}</Text>
                </div>
              ),
            },
            {
              value: `${v}---mentor`,
              label: (
                <div className='search-dropdown'>
                  <span
                    style={{ marginRight: 12 }}
                    className='wc-icon icon-ic_arrow-up-right '></span>
                  <Text className='pre-text'>{`Search "${v}" in `}</Text>
                  <Text style={{ color: '#C00000', fontWeight: 500 }}>
                    &nbsp;Mentor
                  </Text>
                </div>
              ),
            },
            {
              value: `${v}---mentee`,
              label: (
                <div className='search-dropdown'>
                  <span
                    style={{ marginRight: 12 }}
                    className='wc-icon icon-ic_arrow-up-right '></span>
                  <Text className='pre-text'>{`Search "${v}" in `}</Text>
                  <Text style={{ color: '#C00000', fontWeight: 500 }}>
                    &nbsp;Mentee
                  </Text>
                </div>
              ),
            },
            {
              value: `${v}---voice`,
              label: (
                <div className='search-dropdown'>
                  <span
                    style={{ marginRight: 12 }}
                    className='wc-icon icon-ic_arrow-up-right '></span>
                  <Text className='pre-text'>{`Search "${v}" in `}</Text>
                  <Text style={{ color: '#C00000', fontWeight: 500 }}>
                    &nbsp;Voice
                  </Text>
                </div>
              ),
            },
            {
              value: `${v}---community`,
              label: (
                <div className='search-dropdown'>
                  <span
                    style={{ marginRight: 12 }}
                    className='wc-icon icon-ic_arrow-up-right '></span>
                  <Text className='pre-text'>{`Search "${v}" in `}</Text>
                  <Text style={{ color: '#C00000', fontWeight: 500 }}>
                    &nbsp;Community
                  </Text>
                </div>
              ),
            },
          ],
    );
  };

  const menuMobile = (
    <Menu>
      <AppNotifications />
    </Menu>
  );

  const { searchParams: queryParams, modalUserId } = useLayoutContext();
  const { setSearchParams } = useLayoutActionsContext();

  const onApply = (values) => {
    setSearchParams({
      ...values,
      lifeStageIds: [
        ...(values.profileLifeStagesCareer || []),
        ...(values.profileLifeStagesFamily || []),
        ...(values.profileLifeStagesSelf || []),
      ],
    });
    setVisibleFilterModal(false);
  };

  const [keyword, setKeyword] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const kw = searchParams.get('kw')?.split('---')?.[0];
    setKeyword(kw || '');
  }, [location.search, location.pathname]);

  return (
    <Header
      className='app-header-hor-header-fixed'
      style={{
        backgroundColor: sidebarColorSet.sidebarBgColor,
        color: sidebarColorSet.sidebarTextColor,
      }}>
      <div className='header-hor-header-fixed-main'>
        <div className='container'>
          <div className='header-hor-header-fixed-main-flex'>
            <a className='trigger' onClick={showDrawer}>
              <AiOutlineMenu />
            </a>
            <AppLogo hasSidebarColor />
            {user && (
              <AppHorizontalNav
                key={'appNav'}
                className='app-main-hor-header-fixed-menu'
              />
            )}
            {user ? (
              <Space className='search-box' size={4}>
                <AutoComplete
                  dropdownClassName='auto-complete'
                  key={'complete'}
                  dropdownMatchSelectWidth={400}
                  dropdownMatchSelectL
                  options={options}
                  onSelect={onSelect}
                  value={keyword}
                  onSearch={handleSearch}>
                  <Search
                    key={'search'}
                    className='header-search-hor-header-fixed'
                    placeholder={'Search MentoringSG'}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onPressEnter={() => {
                      onSelect(keyword);
                    }}
                  />
                </AutoComplete>
                <Button
                  onClick={() => setVisibleFilterModal(true)}
                  type='link'
                  key='btn'
                  className={`autocomplete-search-hor-header-fixed ${
                    location.pathname.includes('search')
                      ? 'btn-active'
                      : 'btn-inactive'
                  }`}>
                  <span
                    className={`wc-icon icon-sliders`}
                    style={{ fontSize: 20 }}
                  />
                </Button>
              </Space>
            ) : (
              <Menu
                mode='horizontal'
                className='app-main-hor-header-fixed-menu'></Menu>
            )}
            <Space size={0}>
              {user && (
                <div className='app-header-hor-header-fixed-sectionDesktop'>
                  <Link
                    className={`notify-link ${
                      location.pathname.includes('home')
                        ? 'notify-link-active'
                        : ''
                    }`}
                    to={'home'}>
                    <span
                      className={`wc-icon icon-ic_home ${
                        location.pathname.includes('home')
                          ? 'wc-menu-active'
                          : ''
                      }`}
                    />
                  </Link>

                  <Link
                    className={`notify-link ${
                      location.pathname.includes('users')
                        ? 'notify-link-active'
                        : ''
                    }`}
                    to={'/users'}>
                    <span
                      className={`wc-icon icon-ic_network ${
                        location.pathname.includes('users')
                          ? 'wc-menu-active'
                          : ''
                      }`}
                    />
                  </Link>

                  <Link
                    className={`notify-link ${
                      location.pathname.includes('voice')
                        ? 'notify-link-active'
                        : ''
                    }`}
                    to={'voice'}>
                    <span
                      className={`wc-icon icon-ic_message ${
                        location.pathname.includes('voice')
                          ? 'wc-menu-active'
                          : ''
                      }`}
                    />
                  </Link>
                  <Link
                    className={`notify-link ${
                      location.pathname.includes('calendar')
                        ? 'notify-link-active'
                        : ''
                    }`}
                    to={'/calendar'}>
                    <span
                      className={`wc-icon icon-ic_calendar ${
                        location.pathname.includes('calendar')
                          ? 'wc-menu-active'
                          : ''
                      }`}
                    />
                  </Link>
                  <Badge
                    count={totalUnreadChat || 0}
                    style={{ width: 12, height: 12 }}
                    color={totalUnreadChat > 0 ? '#c00000' : undefined}
                    dot={false}
                    offset={[-24, 23]}>
                    <Link
                      className={`notify-link ${
                        location.pathname.includes('chat')
                          ? 'notify-link-active'
                          : ''
                      }`}
                      to={'/chat'}>
                      <span
                        className={`wc-icon icon-ic_chat ${
                          location.pathname.includes('chat')
                            ? 'wc-menu-active'
                            : ''
                        }`}
                      />
                    </Link>
                  </Badge>
                </div>
              )}
              {user && (
                <div className='app-header-hor-header-fixed-sectionDesktop'>
                  <AppNotifications />
                </div>
              )}
              {user && <UserInfo />}
              <div className='app-header-hor-header-fixed-section-mobile'>
                <Dropdown overlay={menuMobile} trigger={['click']}>
                  <a
                    className='ant-dropdown-link'
                    onClick={(e) => e.preventDefault()}>
                    <FiMoreVertical />
                  </a>
                </Dropdown>
              </div>
            </Space>
          </div>
        </div>
      </div>
      {visibleFilterModal && (
        <FilterModal
          visible={visibleFilterModal}
          onCancel={() => setVisibleFilterModal(false)}
          onApply={onApply}
          data={queryParams}
        />
      )}
      {modalUserId && <UserModal userId={modalUserId} />}
    </Header>
  );
};

export default AppHeader;

AppHeader.propTypes = {
  showDrawer: PropTypes.func,
};
