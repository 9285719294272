import axios from 'axios';
import { AppConfigs } from '../../../../shared/config';

const apiUrl = AppConfigs.apiUrl;
const jwtAxios = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    const originalRequest = err.config;

    if (
      err.response &&
      err.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .create({
          baseURL: apiUrl,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .post('/auth/refresh-token', {
          token: localStorage.getItem('refreshToken'),
        })
        .then((res) => {
          const accessToken = res.data.access_token;
          setAuthToken(accessToken);
          localStorage.setItem('refreshToken', res.data.refresh_token);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return jwtAxios(originalRequest);
        })
        .catch((err) => {
          console.log('Need to logout user');
          if (window.location.pathname !== '/signin') {
            setAuthToken();
            window.location.href = '/signin';
          }
          return Promise.reject(err);
        });
    }

    throw err;
  },
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }
};

export default jwtAxios;
