import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';
import ResourceDetailPage from './ResourceDetailPage';

const ResourcePage = React.lazy(() => import('./ResourcePage'));
const ResourceEditPage = React.lazy(() => import('./ResourceEdit'));

export const resourcePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.community,
    path: '/resource/create',
    element: <ResourceEditPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/resource/:id/edit',
    element: <ResourceEditPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/resource/:id',
    element: <ResourceDetailPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/resource',
    element: <ResourcePage />,
  },
];
