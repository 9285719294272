import { Layout } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useLayoutContext } from '../../../utility/AppContextProvider/LayoutContextProvider';
import './AppFooter.style.less';

const { Footer } = Layout;

const AppFooter = () => {
  const { footer } = useLayoutContext();
  const year = new Date().getFullYear();

  if (footer) {
    return (
      <Footer className='app-main-footer text-center d-flex f-1'>
        <Text className='font-14 mt-16 mb-8 pb-4'>
          © Copyright {year} MentoringSG. All rights reserved.
        </Text>
      </Footer>
    );
  }
  return null;
};

export default AppFooter;
