import { Badge, Dropdown, List, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { getDataApi } from '../../utility/APIHooks';

import IntlMessages from '../../utility/IntlMessages';
import AppScrollbar from '../AppScrollbar';
import './index.style.less';
import NotificationItem from './NotificationItem';

const AppNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    const { data: notifications } =
      (await getDataApi('/notifications', {
        take: 30,
        isOnline: true,
      })) || {};
    setNotifications(notifications);
  };

  useEffect(() => {
    getNotifications();
    const interval = setInterval(() => {
      getNotifications();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const data = notifications;
  const menu = (
    <Menu className='notify-header-message'>
      <Menu.Item className='header'>
        <IntlMessages id='common.notifications' />
      </Menu.Item>
      <Menu.Item>
        <AppScrollbar className='notify-scroll-submenu'>
          {!!data?.length && (
            <List
              className='notify-list'
              dataSource={data}
              renderItem={(item) => {
                return <NotificationItem key={item.id} item={item} />;
              }}
            />
          )}
        </AppScrollbar>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} className='dropdown' trigger={['click']}>
      <a className='notify-link' onClick={(e) => e.preventDefault()}>
        <Badge
          count={data?.filter((x) => !x.isRead)?.length || 0}
          style={{ width: 12, height: 12 }}
          color={
            data?.filter((x) => !x.isRead)?.length > 0 ? '#c00000' : undefined
          }
          dot={false}
          offset={[-16, 5]}>
          <span
            className='wc-icon icon-ic_noti'
            style={{ fontSize: 24, color: '#808080' }}
          />
        </Badge>
        <span className='notify-text'>
          <IntlMessages id='common.notifications' />
        </span>
      </a>
    </Dropdown>
  );
};

export default AppNotifications;
