import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRowContainer } from '../../../../@crema';
import { getDataApi, putDataApi } from '../../../../@crema/utility/APIHooks';
import './index.style.less';

const UserRole = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const isCreate = !id;

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const getUser = async () => {
    setIsLoading(true);
    const data = await getDataApi(`users/all/${id}`, {});
    setIsLoading(false);
    if (data) {
      setUser(data);
    }
  };

  const getRoles = async () => {
    setIsLoading(true);
    const res = await getDataApi(`/admin/roles`, {});
    setRoles(res.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (!isCreate) {
      getUser();
    }
  }, []);

  const onFinish = async (values) => {
    const request = {
      ...user,
      ...values,
    };

    setIsLoading(true);

    await putDataApi('/admin/users/' + user.id + '/role', request, {
      fetchSuccess: (res) => {
        setIsLoading(false);
        message.success('Success.');
        navigate(`/user/${res.id}`);
      },
      fetchError: () => {
        setIsLoading(false);
      },
    });
  };

  if (!user?.id) {
    return (
      <>
        <Spin spinning />
      </>
    );
  }

  return (
    <Form
      onFinish={onFinish}
      layout='vertical'
      initialValues={{
        ...user,
      }}>
      <Button type='link' className='pl-0 mb-16' onClick={() => navigate(-1)}>
        <span className='we-icon icon-ic_arrow-left mr-16'></span>
        <span className='pl-16'>BACK</span>
      </Button>
      <h3 className='user-profile-form-title title-primary'>
        {'EDIT USER ROLE'}
      </h3>
      <Row gutter={32} style={{ paddingBottom: 80 }}>
        <Col md={24}>
          <AppRowContainer gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                name='name'
                label={'Name'}
                requiredMark={'optional'}
                rules={[
                  { required: true, message: 'Please input your First Name!' },
                ]}>
                <Input placeholder='Name' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='email'
                label={'Email'}
                requiredMark={'optional'}
                rules={[
                  { required: true, message: 'Please input your First Name!' },
                ]}>
                <Input placeholder='Email' disabled />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name='roleId' label={'Role'}>
                <Select placeholder='Select'>
                  {roles?.map((x) => {
                    return (
                      <Select.Option key={x.id} value={x.id}>
                        {x.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </AppRowContainer>
        </Col>
      </Row>
      <Space className='pb-5' size={100} />
      <Col xs={24} md={24} className='user-profile-footer'>
        <div className='container user-profile-footer_content'>
          <div />
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {'Save'}
          </Button>
        </div>
      </Col>
    </Form>
  );
};

export default UserRole;
