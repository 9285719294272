import _ from 'lodash';
import { authRole } from '../../../shared/constants/AppEnums';

export const getUserFromJwtAuth = (user) => {
  if (user) {
    if (_.isString(user)) {
      user = JSON.parse(user);
    }

    return {
      ...user,
      uid: user._id,
      displayName:
        user.name ||
        `${user.profile?.firstName || ''} ${user?.profile?.lastName || ''}`,
      email: user.email,
      photoURL: user.profile?.avatar,
      role: user.role?.rolePermissions?.map((x) => x.permission.name) || [],
    };
  }

  return user;
};
