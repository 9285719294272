import { Button, Card, Col, Rate, Row, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDataApi } from '../../../@crema/utility/APIHooks';
import {
  getDateDisplay2,
  getShortDateTimeDisplay,
  getTimeDisplay,
} from '../../../@crema/utility/Utils';
import './index.style.less';

const ScheduleDetailPage = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [schedule, setSchedule] = useState({});
  const navigate = useNavigate();

  const getSchedule = async () => {
    setLoading(true);
    const data = await getDataApi(`/schedules/admin/${id}`, {});
    setLoading(false);
    if (data?.data) {
      setSchedule(data?.data);
    }
  };

  useEffect(() => {
    getSchedule();
  }, []);

  return (
    <Spin spinning={loading}>
      <Button type='link' className='pl-0 mb-16' onClick={() => navigate(-1)}>
        <span className='we-icon icon-ic_arrow-left mr-16'></span>
        <span className='pl-16'>BACK</span>
      </Button>
      <Row gutter={8} className='info-avatar'>
        <Col md={24} xs={24}>
          <Card className='card__info'>
            <Title level={4}>{schedule.name}</Title>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>From</Title>
                <Text>{schedule?.connection?.from?.name}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>To</Title>
                <Text>{schedule?.connection?.to?.name}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Created At</Title>
                <Text>{getShortDateTimeDisplay(schedule.createdAt)}</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Start Time</Title>
                <Text>{getDateDisplay2(schedule.startTime)}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Start Time</Title>
                <Text>{getTimeDisplay(schedule.startTime)}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>End Time</Title>
                <Text>{getTimeDisplay(schedule.endTime)}</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Type</Title>
                <Text>{schedule.meetingType}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Meeting URL</Title>
                <Text>
                  <a
                    target={'_blank'}
                    rel='noreferrer'
                    href={schedule.meetingUrl}>
                    {schedule.meetingUrl}
                  </a>
                </Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Location</Title>
                <Text>{schedule.location}</Text>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>Status</Title>
                <Text>{schedule.status}</Text>
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>Rate</Title>
                <Rate disabled value={schedule.rate || 0} allowHalf />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Title level={5}>
                  1. Do you have great experience with the mentor during the
                  session?
                </Title>
                <Rate
                  disabled
                  value={schedule.feedbacks?.[0]?.greatExperience || 0}
                />
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>
                  2. Do you able to apply what you have learned?
                </Title>
                <Rate disabled value={schedule.feedbacks?.[0]?.learn || 0} />
              </Col>
              <Col md={8} xs={24}>
                <Title level={5}>
                  3. Do you want to continue to meet mentor?
                </Title>
                <Rate disabled value={schedule.feedbacks?.[0]?.continue || 0} />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={24} xs={24}>
                <Title level={5}>Notes</Title>
                <Text className='enter-content'>{schedule.notes}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default ScheduleDetailPage;
