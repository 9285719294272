import { Button, Image, Modal, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import _ from 'lodash';
import { useCallback } from 'react';
import './index.style.less';

export const ResourceDetails = ({ data }) => {
  if (!data) {
    return null;
  }

  const tags = data?.tags
    ? data?.tags.split(',').map((item) => item.trim())
    : [];

  const files = _.isArray(data.files) ? data.files : data.files.split(',');
  const downloadFiles = useCallback(() => {
    new Promise((resolve) => {
      const temporaryDownloadLink = document.createElement('a');
      temporaryDownloadLink.style.display = 'none';
      document.body.appendChild(temporaryDownloadLink);
      resolve(temporaryDownloadLink);
    })
      .then((temporaryDownloadLink) => {
        const downloadPromises = [];
        for (const [index, downloadLink] of files.entries()) {
          const downloadPromise = new Promise((resolve) => {
            setTimeout(() => {
              const fileName = downloadLink.split('/').pop();

              temporaryDownloadLink.setAttribute('href', downloadLink.trim());
              temporaryDownloadLink.setAttribute('target', '_blank');
              temporaryDownloadLink.setAttribute('download', fileName);
              temporaryDownloadLink.click();
              resolve(temporaryDownloadLink);
            }, 100 * index);
          });
          downloadPromises.push(downloadPromise);
        }
        return Promise.all(downloadPromises);
      })
      .then(([temporaryDownloadLink]) => {
        document.body.removeChild(temporaryDownloadLink);
      });
  }, []);

  const onDownload = (e) => {
    e.preventDefault();
    downloadFiles();
  };

  return (
    <Row gutter={[15, 15]} style={{ flexDirection: 'column', gap: 0 }}>
      <Row>
        {tags?.map((x, index) => (
          <div>
            <Text className='item-tag' key={index}>
              {x}
            </Text>
          </div>
        ))}
      </Row>
      <Text className='text-title'>{data.title}</Text>
      <Text className='text-description'>{data.description}</Text>
      <Button
        key='submit'
        type='primary'
        className='text-download'
        onClick={onDownload}>
        Download
      </Button>
      <Image
        preview={false}
        width={'100%'}
        style={{ minHeight: 250, borderRadius: 8 }}
        onClick={(e) => e.preventDefault()}
        src={data.image}
      />
    </Row>
  );
};

const ResourcePreviewModal = ({ data, onClose }) => {
  return (
    <Modal
      title={<span style={{ color: '#1890ff' }}>Preview Resource</span>}
      visible={true}
      closable={false}
      width={700}
      footer={
        <Button key='back' className='btn-close' onClick={onClose}>
          Close
        </Button>
      }>
      <ResourceDetails data={data} />
    </Modal>
  );
};

export default ResourcePreviewModal;
