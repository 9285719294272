import { RoutePermittedRole } from '../../shared/constants/AppEnums';
import RolePage from './RolePage/RolePage';
import RoleDetailPage from './RolePage/RoleDetailPage';
import RoleEditPage from './RolePage/RoleEditPage';

export const rolePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.permission,
    path: '/role',
    element: <RolePage />,
  },
  {
    permittedRole: RoutePermittedRole.permission,
    path: '/role/create',
    element: <RoleEditPage />,
  },
  {
    permittedRole: RoutePermittedRole.permission,
    path: '/role/:id',
    element: <RoleDetailPage />,
  },
  {
    permittedRole: RoutePermittedRole.permission,
    path: '/role/:id/edit',
    element: <RoleEditPage />,
  },
];
