import { Button, Col, Row, Space, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDataApi } from '../../../@crema/utility/APIHooks';
import { getShortDateTimeDisplay } from '../../../@crema/utility/Utils';

const RolePage = () => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [total, setTotal] = useState(1);
  const [query, setQuery] = useState({
    pagination: {
      pageSize: 10,
      current: 1,
    },
  });
  const [keyword] = useState('');

  const getRoles = async (options) => {
    setLoading(true);
    const res = await getDataApi(`/admin/roles`, {
      skip: (query.pagination?.current - 1) * query.pagination?.pageSize || 0,
      take: query.pagination?.pageSize || 10,
      search: keyword,
      ...options,
    });

    setRoles(res.data);
    setTotal(res.total);
    setLoading(false);
  };

  useEffect(() => {
    getRoles();
  }, [query.pagination?.current, query.pagination?.pageSize]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: false,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: false,
    },
    {
      title: 'Created at',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return getShortDateTimeDisplay(r.createdAt);
      },
    },
    {
      title: '',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return (
          <Space>
            <Button
              type='primary'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/role/${r.id}`);
              }}>
              View
            </Button>
            <Button
              type='primary'
              danger
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/role/${r.id}/edit`);
              }}>
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setQuery({
      pagination,
      filters,
      sorter,
      extra,
    });
  };

  const navigate = useNavigate();

  return (
    <Row>
      <Title level={1} strong>
        Role Management
      </Title>
      <Col xs={24}>
        <Space className='d-flex-end'>
          <Button
            size='large'
            type='primary'
            onClick={() => navigate(`/role/create`)}>
            Create
          </Button>
        </Space>
      </Col>
      <Col xs={24}>
        <Table
          bordered
          columns={columns}
          dataSource={roles}
          loading={loading}
          onChange={onChange}
          pagination={{ total: total }}
          onRow={() => {
            return {
              onClick: () => {},
            };
          }}
        />
      </Col>
    </Row>
  );
};

export default RolePage;
