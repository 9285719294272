import {
  Avatar,
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getDataApi } from '../../../@crema/utility/APIHooks';

const { Search } = Input;

const ConnectionsPage = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(1);
  const [query, setQuery] = useState({
    pagination: {
      pageSize: 10,
      current: 0,
    },
  });
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState(undefined);
  const [user, setUser] = useState({});

  const getUser = async () => {
    setLoading(true);
    const data = await getDataApi(`users/all/${id}`, {});
    setLoading(false);
    if (data) {
      setUser(data);
    }
  };

  const getUserConnections = async (options) => {
    setLoading(true);
    const res = await getDataApi(`/connections/users/${id}`, {
      skip: (query.pagination?.current - 1) * query.pagination?.pageSize || 0,
      take: query.pagination?.pageSize || 10,
      search: keyword,
      isMentor: type === 'MENTOR' ? true : undefined,
      isMentee: type === 'MENTEE' ? true : undefined,
      isFriend: type === 'FRIEND' ? true : undefined,
      type: type ? (type === 'FRIEND' ? 'Connection' : 'Mentor') : undefined,
      ...options,
    });

    setUsers(res.data);
    setTotal(res.total);
    setLoading(false);
  };

  useEffect(() => {
    if (query.pagination?.current) {
      getUserConnections();
    }
  }, [type, query.pagination?.current, query.pagination?.pageSize]);

  useEffect(() => {
    getUser();
  }, []);

  const onSearch = (value) => {
    setKeyword(value);
    getUserConnections({ search: value });
  };

  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      sorter: false,
      render: (_r, { from, to }) => {
        if (from.id !== id) return <Avatar src={from.avatar} />;
        return <Avatar src={to.avatar} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: false,
      render: (_r, { from, to }) => {
        if (from.id !== id) {
          return (
            <Link
              className='text-secondary'
              onClick={(e) => e.stopPropagation()}
              to={`/user/${from.id}`}>
              {from.name}
            </Link>
          );
        }
        return (
          <Link
            className='text-secondary'
            onClick={(e) => e.stopPropagation()}
            to={`/user/${to.id}`}>
            {to.name}
          </Link>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: false,
      render: (_r, { type, from }) => {
        if (type === 'Connection') return 'Friend';
        if (from.id === id) return 'Mentor';
        return 'Mentee';
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: false,
      render: (_r, { from, to }) => {
        if (from.id !== id) return from.email;
        return to.email;
      },
    },
    {
      title: 'Level',
      dataIndex: 'level',
      sorter: false,
      render: (r, { from, to }) => {
        const level = from.id !== id ? from.profile?.level : to.profile?.level;
        let color = level === -1 ? 'blue' : level === 0 ? 'orange' : 'red';

        const levelName = level === -1 ? 'Mentee' : `Mentor ${level}`;

        return (
          <Tag color={color} key={level}>
            {levelName}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return (
          <Space>
            <Button
              type='primary'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/schedules?connectionId=${r.id}`);
              }}>
              Session
            </Button>
            <Button
              type='ghost'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/user/${r.from.id !== id ? r.from.id : r.to.id}`);
              }}>
              View user
            </Button>
          </Space>
        );
      },
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setQuery({
      pagination,
      filters,
      sorter,
      extra,
    });
  };

  const navigate = useNavigate();

  return (
    <Row>
      <Col xs={24} className='pl-0 mb-0'>
        <Button
          type='link'
          className='pl-0 mb-0'
          onClick={() => navigate(`/user`)}>
          <span className='we-icon icon-ic_arrow-left mr-16'></span>
          <span className='pl-16'>BACK</span>
        </Button>
      </Col>
      <Title level={1} strong>
        {user?.name}&apos;s Connections ({user?.email})
      </Title>
      <Col xs={24}>
        <Space>
          <Search
            placeholder='Search by name or email'
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            style={{ width: 400 }}
          />
          <Select
            style={{ width: 220 }}
            value={type}
            placeholder='Type'
            allowClear
            onChange={(e) => setType(e)}>
            <Select.Option value={'MENTOR'}>Mentor</Select.Option>
            <Select.Option value={'MENTEE'}>Mentee</Select.Option>
            <Select.Option value={'FRIEND'}>Friend</Select.Option>
          </Select>
        </Space>
      </Col>
      <Col xs={24}>
        <Table
          bordered
          columns={columns}
          dataSource={users}
          loading={loading}
          onChange={onChange}
          pagination={{ total: total }}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/connections/${record.id}`);
              },
            };
          }}
        />
      </Col>
    </Row>
  );
};

export default ConnectionsPage;
