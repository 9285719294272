import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const BannerPage = React.lazy(() => import('./BannerPage'));

export const bannerPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.content,
    path: '/banner',
    element: <BannerPage />,
  },
];
