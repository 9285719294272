import React from 'react';

const UserProfile = React.lazy(() => import('./UserProfile'));
const AccountSetup = React.lazy(() => import('./UserProfile/AccountSetup'));
const ChangePasswordPage = React.lazy(
  () => import('./UserProfile/ChangePasswordPage'),
);

const ConfigureMentee = React.lazy(
  () => import('./UserProfile/ConfigureMentee'),
);

const MenteeTerm = React.lazy(() => import('./UserProfile/MenteeTerm'));
const SelectProfile = React.lazy(() => import('./UserProfile/SelectProfile'));

export const profileConfig = [
  {
    path: '/account-setup',
    element: <AccountSetup />,
  },
  {
    path: '/my-profile',
    element: <UserProfile />,
  },
  {
    path: 'change-password',
    element: <ChangePasswordPage />,
  },
  {
    path: 'configure-mentee',
    element: <ConfigureMentee />,
  },
  {
    path: 'select-profile',
    element: <SelectProfile />,
  },
  {
    path: 'mentee-term-conditions',
    element: <MenteeTerm />,
  },
];
