import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDataApi } from '../../../@crema/utility/APIHooks';
import { AppConfigs } from '../../../shared/config';
import { ResourceDetails } from '../ResourcePreviewModal';

const ResourceDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(false);

  const getResource = async () => {
    const res = await getDataApi(
      `${AppConfigs.apiV2Url}/api-svc/v1/resources/${id}`,
      {},
    );
    if (res) {
      setResource(res.data);
    }
  };

  useEffect(() => {
    if (id) {
      getResource();
    }
  }, [id]);

  if (!resource) {
    return <Skeleton />;
  }

  return (
    <Row className='voice-detail-page'>
      <Col xs={24} className='mb-8'>
        <Button
          className='pl-0'
          onClick={() => navigate('/resource')}
          type='link'>
          <LeftOutlined style={{ marginRight: 8 }} />
          MENTORING RESOURCES
        </Button>
      </Col>
      <Col xs={20}>
        <ResourceDetails data={resource} />
      </Col>
    </Row>
  );
};

export default ResourceDetailPage;
