import { articlePagesConfigs } from './article';
import TermAndCondition from './article/ArticleDetailPage/TermAndCondition';
import { authRouteConfig } from './auth';
import { errorPagesConfigs } from './errorPages';
import Error403 from './errorPages/Error403';
import Error404 from './errorPages/Error404';
import { homePagesConfigs } from './home';
import { groupPagesConfigs } from './group';
import { profileConfig } from './profile';
import { supportPagesConfigs } from './support';
import { voicePagesConfigs } from './voice';
import { userPagesConfigs } from './user';
import { mentorRequestPagesConfigs } from './mentor-request';
import { bannerPagesConfigs } from './banner';
import { reportPagesConfigs } from './report';
import { rolePagesConfigs } from './role';
import { eventPagesConfigs } from './event';
import { resourcePagesConfigs } from './resource';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...userPagesConfigs,
    ...homePagesConfigs,
    ...articlePagesConfigs,
    ...groupPagesConfigs,
    ...supportPagesConfigs,
    ...voicePagesConfigs,
    ...eventPagesConfigs,
    ...profileConfig,
    ...bannerPagesConfigs,
    ...mentorRequestPagesConfigs,
    ...reportPagesConfigs,
    ...rolePagesConfigs,
    ...resourcePagesConfigs,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: '/user',
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/terms-and-conditions',
      element: <TermAndCondition />,
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
