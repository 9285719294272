import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const GroupPage = React.lazy(() => import('./EventPage'));
const GroupEditPage = React.lazy(() => import('./EventEdit'));
const GroupDetailPage = React.lazy(() => import('./EventDetailPage'));

export const eventPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.community,
    path: '/event/:id/edit',
    element: <GroupEditPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/event/create',
    element: <GroupEditPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/event/:id',
    element: <GroupDetailPage />,
  },
  {
    permittedRole: RoutePermittedRole.community,
    path: '/event',
    element: <GroupPage />,
  },
];
