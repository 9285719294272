import { Button, Col, Input, Row, Space, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getDataApi } from '../../../@crema/utility/APIHooks';
import {
  getDateDisplay2,
  getShortDateTimeDisplay,
  getTimeDisplay,
} from '../../../@crema/utility/Utils';

const { Search } = Input;

const SchedulesPage = () => {
  const [loading, setLoading] = useState(false);
  const [q] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(1);
  const connectionId = q.get('connectionId');
  const [query, setQuery] = useState({
    pagination: {
      pageSize: 10,
      current: 0,
    },
  });
  const [keyword, setKeyword] = useState('');
  const [type] = useState(undefined);
  const [connection, setConnection] = useState({});

  const getConnection = async () => {
    setLoading(true);
    const data = await getDataApi(`connections/admin/${connectionId}`, {});
    setLoading(false);
    if (data) {
      setConnection(data);
    }
  };
  useEffect(() => {
    if (connectionId) {
      getConnection();
    }
  }, [connectionId]);

  const getSchedules = async (options) => {
    setLoading(true);
    const res = await getDataApi(`/schedules/all`, {
      skip: (query.pagination?.current - 1) * query.pagination?.pageSize || 0,
      take: query.pagination?.pageSize || 10,
      search: keyword,
      ...options,
      connectionId: connectionId,
    });

    setUsers(res.data);
    setTotal(res.total);
    setLoading(false);
  };

  useEffect(() => {
    if (query.pagination?.current) {
      getSchedules();
    }
  }, [type, query.pagination?.current, query.pagination?.pageSize]);

  useEffect(() => {}, []);

  const onSearch = (value) => {
    setKeyword(value);
    getSchedules({ search: value });
  };

  const columns = [
    {
      title: 'Session Name',
      dataIndex: 'name',
      sorter: false,
    },
    {
      title: 'From',
      dataIndex: '',
      sorter: false,
      render: (r) => (
        <Link
          className='text-secondary'
          onClick={(e) => e.stopPropagation()}
          to={`/user/${r.connection?.fromId}`}>
          {r.connection?.from?.name}
        </Link>
      ),
    },
    {
      title: 'To',
      dataIndex: '',
      sorter: false,
      render: (r) => (
        <Link
          className='text-secondary'
          onClick={(e) => e.stopPropagation()}
          to={`/user/${r.connection?.toId}`}>
          {r.connection?.to?.name}
        </Link>
      ),
    },
    {
      title: 'Date',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return getDateDisplay2(r.startTime);
      },
    },
    {
      title: 'Start Time',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return getTimeDisplay(r.startTime);
      },
    },
    {
      title: 'End Time',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return getTimeDisplay(r.endTime);
      },
    },
    {
      title: 'Created at',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return getShortDateTimeDisplay(r.createdAt);
      },
    },
    {
      title: 'Type',
      dataIndex: 'meetingType',
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      sorter: false,
      render: (r) => {
        return r?.toFixed(2);
      },
    },
    {
      title: '',
      dataIndex: '',
      sorter: false,
      render: (r) => {
        return (
          <Space>
            <Button
              type='primary'
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/schedules/${r.id}`);
              }}>
              View
            </Button>
          </Space>
        );
      },
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const onChange = (pagination, filters, sorter, extra) => {
    setQuery({
      pagination,
      filters,
      sorter,
      extra,
    });
    setSearchParams({ page: pagination.current });
  };

  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());
    setQuery({
      ...query,
      pagination: {
        ...query.pagination,
        current: parseInt(params.page || 1),
      },
    });
  }, [searchParams]);

  const navigate = useNavigate();

  return (
    <Row>
      {!!connectionId && (
        <Col xs={24} className='pl-0 mb-0'>
          <Button
            type='link'
            className='pl-0 mb-0'
            onClick={() => navigate(-1)}>
            <span className='we-icon icon-ic_arrow-left mr-16'></span>
            <span className='pl-16'>BACK</span>
          </Button>
        </Col>
      )}
      {connectionId ? (
        <Title level={1} strong>
          {connection?.from?.name} - {connection?.to?.name}&apos;s Mentoring
          Sessions
        </Title>
      ) : (
        <Title level={1} strong>
          Mentoring Sessions
        </Title>
      )}
      <Col xs={24}>
        <Space>
          <Search
            placeholder='Search by name'
            onSearch={onSearch}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            style={{ width: 400 }}
          />
        </Space>
      </Col>
      <Col xs={24}>
        <Table
          bordered
          columns={columns}
          dataSource={users}
          loading={loading}
          onChange={onChange}
          pagination={{
            total: total,
            current: query.pagination.current,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          }}
          onRow={() => {
            return {
              onClick: () => {},
            };
          }}
        />
      </Col>
    </Row>
  );
};

export default SchedulesPage;
