import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const VoicePage = React.lazy(() => import('./VoicePage'));
const VoiceDetailPage = React.lazy(() => import('./VoiceDetailPage'));

export const voicePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.voice,
    path: '/voice/:id',
    element: <VoiceDetailPage />,
  },
  {
    permittedRole: RoutePermittedRole.voice,
    path: '/voice',
    element: <VoicePage />,
  },
];
